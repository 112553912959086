import React from "react";
import aniedi from "images/aniedi.png";
import groupImg from "images/comm-gradient.png";
import erik from "images/erik.png";
import haneef from "images/haneefah.png";
import ibrahim from "images/ibrahim.png";
import kirsti from "images/kirsti.png";
import marvis from "images/marvis.png";
import micheal from "images/michael.png";
import oliver from "images/oliver.png";
// import erin from "images/erin.png";
// import samson from "images/samson.png";
// import samu from "images/samu.png";
import Layout from "components/Layout";
import Seo from "components/Seo";
// import Sponsors from "components/Sponsors";

const Summit = () => {
  return (
    <Layout>
      <Seo
        title="Community Leaders Summit 2023"
        description="The Community Leads Africa Summit brings together community managers and builders in a two-day learning experience to bridge the gap in building and managing credible communities in Africa.

        Community builders and managers will better understand what community is and how to build and manage a sustainable community in their various localities and companies."
      />
      <section>
        <div className="container max-w-screen-xl mx-auto py-20 px-6">
          <div className=" flex justify-center">
            <div className="text-center">
              <h2 className="text-2xl font-bold">
                June 30th & July 1st 2023 | Community Leads in Africa Summit
              </h2>
              <h1 className="md:text-6xl text-5xl font-bold mt-7">#CLAS23</h1>
              <div className="flex justify-center mt-3">
                <p className="md:w-3/5 w-full text-lg text-center text-gray-700">
                  A virtual event for community builders, leaders, managers, and
                  developers advocates in and within Africa. It's 100% free.
                </p>
              </div>

              <div className="mt-12">
                <a
                  href="http://nas.io/cla/summit23/"
                  rel="noopener noreferrer"
                  aria-label="CLA Summit23 Join Link"
                  target="_blank"
                  className="bg-primary text-white px-11 py-4 rounded-lg"
                >
                  Join Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container max-w-screen-xl mx-auto py-10 px-4 flex justify-center">
          <div className="bg-primary-light p-10 md:w-2/3 w-full rounded-xl flex justify-around text-center items-center md:flex-row flex-col">
            <div className="md:mb-0 mb-8">
              <h2 className="text-5xl font-bold">2</h2>
              <h5>Days of Contents</h5>
            </div>
            <div className="md:mb-0 mb-8">
              <h2 className="text-5xl font-bold">500+</h2>
              <h5>Attendees</h5>
            </div>
            <div className="md:mb-0 mb-8">
              <h2 className="text-5xl font-bold">25+</h2>
              <h5>Speakers & Sessions</h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container max-w-screen-xl mx-auto py-10 flex justify-center px-4">
          <div className=" grid md:grid-cols-4 grid-cols-1 gap-18 gap-10 md:flex-row">
            <div className="col-span-2">
              <img
                className="rounded-xl"
                src={groupImg}
                alt="Community Image"
              />
            </div>
            <div className="col-span-2">
              <p className="mt-6 text-gray-700">
                The Community Leads Africa Summit brings together community
                managers and builders in a two-day learning experience to bridge
                the gap in building and managing credible communities in Africa.
              </p>
              <p className="mt-6 text-gray-700">
                Community builders and managers will better understand what
                community is and how to build and manage a sustainable community
                in their various localities and companies.
              </p>
              <p className="mt-6 text-gray-700">
                <span className="font-bold text-xl block mb-3">
                  Who is invited to #CLASummit23?{" "}
                </span>
                Everyone is welcome to attend the summit, from African community
                builders to leaders and executives in the community industries,
                community-led startups and companies, developer relations and
                developer advocates, and community builders.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <Sponsors title="Sponsors & Partners" /> */}
      <section>
        <div className="container max-w-screen-xl mx-auto py-20 px-6">
          <h2 className="font-bold text-center text-4xl mb-5">
            Meet Your Speakers
          </h2>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-10">
            <div>
              <img
                className="rounded-xl"
                src={haneef}
                alt="Haneefah Abdurrahman"
              />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Haneefah Abdurrahman</h5>
                <p>Program Manager at Ingressive For Good</p>
              </div>
            </div>
            <div>
              <img
                className="rounded-xl"
                src={aniedi}
                alt="Aniedi Udo-Obong "
              />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Aniedi Udo-Obong</h5>
                <p> Program Manager at Google </p>
              </div>
            </div>
            <div>
              <img className="rounded-xl" src={erik} alt="Erik" />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Erik Martin </h5>
                <p>Head of Community Led at Commsor</p>
              </div>
            </div>
            <div>
              <img className="rounded-xl" src={ibrahim} alt="Ibraheem" />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Ibraheem Zulkifli</h5>
                <p>Developer Community Manager</p>
              </div>
            </div>
            <div>
              <img className="rounded-xl" src={micheal} alt="Michael Oladele" />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Michael Oladele</h5>
                <p>Marketing & Community at Cowrywise</p>
              </div>
            </div>
            <div>
              <img className="rounded-xl" src={kirsti} alt="Kirsti" />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Kirsti Lang </h5>
                <p>Content Lead at Commsor + Community Club</p>
              </div>
            </div>
            <div>
              <img className="rounded-xl" src={marvis} alt="Marvis" />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Marvis Ighedosa</h5>
                <p>Co-Founder at UntitledDesigners</p>
              </div>
            </div>
            <div>
              <img className=" rounded-xl" src={oliver} alt="Oliver" />
              <div className="mt-3 text-center">
                <h5 className="text-lg font-bold">Oliver Mensah</h5>
                <p>Devrel at DigitalOcean</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Summit;
